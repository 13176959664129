<template>
    <v-modal
        ref="modal"
        :name="name"
        :resizable="userCanResize"
        :adaptive="false"
        :draggable="'.vue-dialog-content-title'"
        :scrollable="false"
        :reset="true"
        :classes="['x-modal']"
        :width="computedWidth"
        :height="computedHeight"
        :min-height="150"
        :min-width="300"
        :click-to-close="false"
        @resize="debouncedResize"
        @before-open="beforeOpenHandler"
        @before-close="beforeCloseHandler"
        @opened="modalOpened"
    >
        <div class="top-right-buttons">
            <template v-for="(button, index) in topRightButtons">
                <button
                    :key="index"
                    v-bind="button.options"
                    @click="button.click()"
                    v-html="button.text"
                />
            </template>
        </div>

        <div
            v-if="!loading"
            class="vue-dialog-content"
        >
            <div
                class="vue-dialog-content-title"
            >
                <slot name="title" />
            </div>

            <div
                ref="dialogBody"
                :class="bodyClasses"
            >
                <slot name="content">
                    Modal content
                </slot>
            </div>

            <slot name="buttons">
                <div
                    v-if="buttons.length > 0"
                    :class="buttonsContainerClass"
                >
                    <template v-for="(button, index) in buttons">
                        <button
                            v-if="!checkButtonIsXButtonComponent(button)"
                            :key="index"
                            v-bind="button.options"
                            type="button"
                            class="btn btn-primary"
                            @click="button.click()"
                            v-html="button.text"
                        />
                        <x-button
                            v-else
                            :key="index"
                            :button="button.button"
                            :additional-actions="getPreparedAdditionalActionOptionsForXButtonComponent(button)"
                        />
                    </template>
                </div>
            </slot>
        </div>

        <div
            v-if="loading"
            class="vue-dialog-content vue-modal-preloader"
        >
            <div class="vue-dialog-content-title">
                {{ $t('common', 'Loading...') }}
            </div>

            <x-bootstrap-spinner size="lg" />
        </div>
    </v-modal>
</template>

<script>
import XBootstrapSpinner from '@/components/common/XBootstrapSpinner/XBootstrapSpinner';
import { debounce, getWindowHeight, getWindowWidth } from '@/utils/functions';
import XButton from '@/components/common/buttons/XButton/XButton';
import { mapGetters } from 'vuex';

/* todo: Draggable property when mount component v-modal can be a querySelector error, this is a bug in the modal plugin itself */
export default {
    name: 'XModal',
    components: { XButton, XBootstrapSpinner },
    props: {
        name: String,
        loading: {
            type: Boolean,
            default: false,
        },
        width: {
            type: [Number, String],
            default: 'auto',
        },
        height: {
            type: [Number, String],
            default: 'auto',
        },
        buttons: {
            type: Array,
            default() {
                return [
                    {
                        text: this.$t('common', 'Close'),
                        click: () => {
                            this.$modal.close(this.name);
                        },
                    },
                ];
            },
        },
        closeByEsc: {
            type: Boolean,
            default: true,
        },
        buttonsContainerClass: {
            type: [Object, Array],
            default() {
                return ['vue-dialog-buttons'];
            },
        },
        disabledScrollBody: {
            type: Boolean,
            default: false,
        },
        bodyContainerClass: String,
        showCloseButton: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            customCallback: () => {
            },
            windowWidth: 0,
            windowHeight: 0,
            isClosingConfirmed: false,
            confirmActionModalOpen: false,
            debouncedResize: debounce(() => {
                if (typeof window.activeDT !== 'undefined') {
                    window.activeDT.draw();
                }
            }, 500),
        };
    },
    computed: {
        ...mapGetters('sidebar', [
            'sidebarState',
        ]),
        bodyClasses() {
            return ['vue-dialog-body', {
                'overflow-hidden': this.disabledScrollBody,
                [this.bodyContainerClass || '']: !!this.bodyContainerClass,
            }];
        },
        computedHeight() {
            if (this.loading) {
                if (this.windowHeight <= this.height) {
                    return this.normalizeHeight(this.height);
                }
                return this.height || 100;
            }

            return this.normalizeHeight(this.height);
        },
        computedWidth() {
            if (this.loading) {
                if (this.windowWidth <= this.width) {
                    return this.normalizeWidth(this.width);
                }
                return this.width || 300;
            }

            return this.normalizeWidth(this.width);
        },
        topRightButtons() {
            if (!this.showCloseButton) {
                return [];
            }

            return [
                {
                    options: {
                        class: 'modal-close-button',
                        title: this.$t('common', 'Close'),
                    },
                    click: () => {
                        this.$modals.close(this.name);
                    },
                    text: '<i class="icon-ic_fluent_dismiss_24_regular" />',
                },
            ];
        },
        needActionsWithConfirmModal() {
            return (window.splynxDialogs.length >= 1 && this.name === 'confirm-action-modal') || this.sidebarState;
        },
        userCanResize() {
            return this.windowWidth > 768;
        },
    },
    watch: {
        computedHeight() {
            this.applyResize();
        },
        computedWidth() {
            this.applyResize();
        },
        loading(newValue) {
            this.$nextTick(() => {
                if (newValue) {
                    this.$emit('start-loading');
                } else {
                    splynx_event_bus.emit('vue-modal-loaded', this.$refs.dialogBody);
                    this.$emit('loaded');
                }
            });
        },
    },
    beforeMount() {
        this.customCallback = window.getCustomCallback(this.name);
        if (this.closeByEsc) {
            window.addEventListener('keyup', this.onEscapeKeyUp);
        }

        window.addEventListener('resize', this.onWindowResize);
    },
    beforeDestroy() {
        if (this.closeByEsc) {
            window.removeEventListener('keyup', this.onEscapeKeyUp);
        }

        window.removeEventListener('resize', this.onWindowResize);
        this.customCallback.closed(this);
    },
    created() {
        this.windowWidth = getWindowWidth();
        this.windowHeight = getWindowHeight();
    },
    methods: {
        modalOpened() {
            this.customCallback.opened(this);
        },
        beforeOpenHandler() {
            $('body').addClass('modal-open');
        },
        checkDialogHasUnsavedData() {
            return checkUnsavedDataBeforeCloseDialog(this.$refs.modal.$el, () => {
                this.isClosingConfirmed = true;
                this.$modals.dismiss(this.name);
            });
        },
        beforeCloseHandler(event) {
            if (this.loading || (!this.isClosingConfirmed && this.checkDialogHasUnsavedData())) {
                if (event.cancel) {
                    event.cancel();
                }
                return false;
            }

            $('body').removeClass('modal-open');
            this.$modals.remove(this.name);
            this.$emit('before-close', event);
        },
        applyResize() {
            this.$nextTick(() => {
                this.$refs.modal.setInitialSize();
            });
        },
        onEscapeKeyUp(event) {
            if (event.which === 27) {
                if (this.needActionsWithConfirmModal) {
                    if (this.confirmActionModalOpen) {
                        this.confirmActionModalOpen = false;
                        this.$modals.close(this.name);
                    } else {
                        this.confirmActionModalOpen = true;
                        this.$modal.show(this.name);
                    }

                    return true;
                }

                this.beforeCloseHandler(event);
                this.$modals.dismiss(this.name);
            }
        },
        normalizeHeight(height) {
            return height > this.windowHeight ? this.windowHeight - 20 : height;
        },
        normalizeWidth(width) {
            return width > this.windowWidth ? this.windowWidth - 20 : width;
        },
        onWindowResize() {
            this.windowWidth = getWindowWidth();
            this.windowHeight = getWindowHeight();
        },
        checkButtonIsXButtonComponent(buttonConfig) {
            return isset(buttonConfig, ['button']);
        },
        getPreparedAdditionalActionOptionsForXButtonComponent(buttonConfig) {
            return typeof buttonConfig.additionalActions === 'undefined' ? {} : buttonConfig.additionalActions;
        },
    },
};
</script>
